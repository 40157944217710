import $ from 'jquery'
import queryString from 'query-string'
import { ArcAds } from 'arcads'

import * as api from './kong.api'
import * as ui from './ui'
import * as wrapper from './wrapper'
import log from './console'
import './handlers'
import './metrics'

import 'typeface-roboto'
import '../styles/home.scss'
import _ from 'lodash'

log.init()

let urlParams = queryString.parse(location.search)

if(localStorage.getItem('authToken')) {
    init()
} else {
    api.getAuthToken(urlParams.id, urlParams.client_key)
        .then(evt => {
            init()
        })
}

async function init() {

    api.makeAjaxWrapper()

    let wrapperEl = $('#wrapper')
    wrapperEl.hide()

    // dynamic content
    // TODO: only call this logic once, then store config locally?
    let json = await api.getConfig(localStorage.getItem('id') || urlParams.id)
        .catch(err => {
            console.error(err)
            localStorage.removeItem('authToken')

            // TODO: there's probably a better way
            window.location.reload()
        })

    if (wrapper.isAndroid) {
        $('header').hide()
    } else {
        ui.updateTheme(json)
        ui.updateIconMenu(json)
    }

    if (json.settings && json.settings.dfp_id) {
        let arcAds = new ArcAds({
            dfp: {
                id: json.settings.dfp_id
            }
        })

        // load ads
        arcAds.registerAd({
            id: 'banner_horizontal',
            slotName: 'KONG_MW_Main_0002',
            dimensions: [[970, 90], [728, 90], [320, 50]]
        })

        arcAds.registerAd({
            id: 'banner_vertical',
            slotName: 'KONG_MAIN_MW_001',
            dimensions: [[300, 250], [300, 600], [320, 50]]
        })
    }

    const topHeadlinesSlots = 5
    const subCarouselSlots = 3
    const featuredSlots = 2
    const subFeaturedSlots = 3
    const footerSlots = 3
    const totalSlots = topHeadlinesSlots + subCarouselSlots + featuredSlots + subFeaturedSlots + footerSlots

    let adItems = json.ads || []
    let feed = await api.getFeed(totalSlots - adItems.length)
    let feedItems = feed.items

    let topHeadlineAds =  _.filter(json.ads, {'slot':'top-headlines'})
    let subCarouselAds =  _.filter(json.ads, {'slot':'sub-carousel'})
    let featuredAds =  _.filter(json.ads, {'slot':'featured'})
    let subFeaturedAds =  _.filter(json.ads, {'slot':'sub-featured'})
    let footerAds =  _.filter(json.ads, {'slot':'footer'})

    let topHeadlineItems = _.concat(topHeadlineAds, feedItems.splice(0, topHeadlinesSlots - topHeadlineAds.length))
    let subCarouselItems = _.concat(subCarouselAds, feedItems.splice(0, subCarouselSlots - subCarouselAds.length))
    let featuredItems = _.concat(featuredAds, feedItems.splice(0, featuredSlots - featuredAds.length))
    let subFeaturedItems = _.concat(subFeaturedAds, feedItems.splice(0, subFeaturedSlots - subFeaturedAds.length))
    let footerItems = _.concat(footerAds, feedItems.splice(0, footerSlots - footerAds.length))

    $.when.apply($, [
        ui.updateHeadlines(topHeadlineItems, topHeadlinesSlots),
        ui.updateSubCarousel(subCarouselItems, subCarouselSlots),
        ui.updateFeatured(featuredItems, featuredSlots),
        ui.updateSubFeatured(subFeaturedItems, subFeaturedSlots),
        ui.updateFooter(footerItems, footerSlots)
    ])
    .then(_ => {
        wrapperEl.show()
    })
}

if (wrapper.isAndroid) {
    // JavascriptInterface in Android wrapper, to call from JS
    Android.updateCarouselRect(JSON.stringify(document.querySelector('#carousel').getBoundingClientRect()))

    $(window).scroll( _ => {
        Android.updateCarouselRect(JSON.stringify(document.querySelector('#carousel').getBoundingClientRect()))
    })
}

// exported function to call from Android wrapper
export function getCarouselRect() {
    return JSON.stringify(document.querySelector('#carousel').getBoundingClientRect())
}

export function hideHeader() {
    $('header').hide()
}