function template(strings, ...keys) {
    return (function(...values) {
        var dict = values[values.length - 1] || {}
        var result = [strings[0]]
        keys.forEach(function(key, i) {
            var value = Number.isInteger(key) ? values[key] : dict[key]
            result.push(value, strings[i + 1])
        })
        return result.join('')
    })
}

export var topHeadlineItem = template`
<li>
    <a href="${'url'}">
        ${'title'}
    </a>
</li>
`

export var subCarouselItem = template`
<a href="${'url'}">
    <article>
        <div class="img" style="background-image:url(${'image'})"></div>
        <h1>${'title'}</h1>
    </article>
</a>
`

export var featuredItem = template`
<a href="${'url'}">
    <article style="background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.55) 63%, #000000), url(${'image'})">
        <span>${'label'}</span>
        <h3>${'title'}</h3>
    </article>
</a>    
`

export var subFeaturedItem = template`
<a href="${'url'}">
    <article>
        <div class="thumb" style="background-image:url(${'image'})"></div>
        <span class="category">${'label'}</span>
        <h3>${'title'}</h3>
        <p>${'content'}</p>
    </article>
</a>    
`

export var footerItem = template`
<a href="${'url'}">
    <article>
        <div class="thumb" style="background-image:url(${'image'})"></div>
        <h3>${'title'}</h3>
        <p>${'content'}</p>
    </article>
</a>
`

export var viatorAttractionItem = template`
<div class="viator-item">
    <a href="${'webURL'}">
        <img src="${'thumbnailHiResURL'}" class="thumb" alt="${'title'}">
        <h4>${'title'}</h4>
        <div class="star-ratings-sprite"><span style="width:${'widthPct'}%" class="star-ratings-sprite-rating"></span></div>
    </a>
</div>
`

export var goldstarAttractionItem = template`
<div class="goldstar-item">
    <a href="${'webURL'}">
        <div class="thumb-wrapper">
            <div class="date">${'date'}</div>
            <img src="${'thumbnailHiResURL'}" class="thumb" alt="${'title'}">
        </div>
        <h4>${'title'}</h4>
        <h5>${'subtitle'}</h5>
        <p>${'description'}</p>
    </a>
</div>
`