import gtag, {install} from 'ga-gtag'
import queryString from 'query-string'

let urlParams = queryString.parse(location.search)

if (process.env.GTAG) install(process.env.GTAG)

if(urlParams.serial) {
	localStorage.setItem('serial', urlParams.serial)
	gtag('set', 'user_properties', { serial: urlParams.serial })
}

if(urlParams.id) {
	gtag('set', 'user_properties', { property_id: urlParams.id })
}