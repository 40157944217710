import $ from 'jquery'
import _ from 'lodash'
import gtag from 'ga-gtag'

import * as api from './kong.api'
import * as templates from './templates'

_.insert = (n, ins, arr) => [...arr.slice(0, n), ins, ...arr.slice(n)]

export function updateSection({selector, items, max, template, imageSize}) {
    let el = $(selector)
    el.empty()

    // first add content
    let allItems = _.filter(items, (item) => { return item.position == null })

    // then add ads
    _.filter(items, (item) => { return !isNaN(item.position) }).forEach( item => {
        allItems = _.insert(item.position, item, allItems)
    })

    allItems.forEach(item => {

        if (item.slot) {
            // AD
            addItem(
                el,
                template({
                    url: item.url,
                    image: item.image,
                    title: item.title,
                    label: item.label,
                    content: item.description
                }),
                max
            )

            gtag('event', 'webview_impression', {
                url: item.url,
                image: item.image,
                title: item.title,
                label: item.label,
                content: item.description
            })

        } else if (item.key) {
            // CONTENT
            if (process.env.NODE_ENV === 'local' || !imageSize) {
                addItem(
                    el,
                    template({
                        url: `content.html?key=${item.key}`,
                        image: item.image,
                        title: item.title,
                        label: item.source.label,
                        content: item.contentSnippet
                    }),
                    max
                )

                gtag('event', 'webview_impression', {
                    url: `content.html?key=${item.key}`,
                    image: item.image,
                    title: item.title,
                    label: item.source.label,
                    content: item.contentSnippet
                })

            } else {

                // TODO: add item with placeholder graphic now
                let newItem = addItem(
                    el,
                    template({
                        url: `content.html?key=${item.key}`,
                        //image: item.image,
                        title: item.title,
                        label: item.source.label,
                        content: item.contentSnippet
                    }),
                    max
                )

                gtag('event', 'webview_impression', {
                    url: `content.html?key=${item.key}`,
                    image: item.image,
                    title: item.title,
                    label: item.source.label,
                    content: item.contentSnippet
                })

                api.getImage(imageSize.width, imageSize.height, item.image)
                    .then(res => {
                        if (selector === '#sub-carousel') {
                            $('div.img', newItem).attr('style', `background-image: url(${res})`)
                        }
                        else if(selector === '#featured') {
                            $('article', newItem).attr('style', `background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.55) 63%, #000000), url(${res})`)
                        }
                        else if(selector === '#sub-featured' || selector === 'footer') {
                            $('div.thumb', newItem).attr('style', `background-image: url(${res})`)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }
        }

    })
}

export async function updateHeadlines(items, max) {
    updateSection({
        selector: '#top-headlines',
        items,
        max,
        template: templates.topHeadlineItem
    })
}

export function updateSubCarousel(items, max) {
    updateSection({
        selector: '#sub-carousel',
        items,
        max,
        template: templates.subCarouselItem,
        imageSize: { width: 240, height: 130 }
    })
}

export function updateFeatured(items, max) {
    updateSection({
        selector: '#featured',
        items,
        max,
        template: templates.featuredItem,
        imageSize: { width: 540, height: 300 }
    })
}

export function updateSubFeatured(items, max) {
    updateSection({
        selector: '#sub-featured',
        items,
        max,
        template: templates.subFeaturedItem,
        imageSize: { width: 240, height: 214 }
    })
}

export function updateFooter(items, max) {
    updateSection({
        selector: 'footer',
        items,
        max,
        template: templates.footerItem,
        imageSize: { width: 340, height: 196 }
    })
}

export function updateTheme(json) {
    $('#logo').css('background-image', `url(${json.settings.theme.logo})`)

    // check for survey
    if (json.settings.survey_url) {
        $('.survey a').attr('href', json.settings.survey_url)
    } else {
        $('.survey').hide()
    }
}

export function updateIconMenu(json) {

    let iconMenuEl = $('#icon-menu')

    iconMenuEl.empty()

    // TODO: redo this in templates.js
    json.settings.icon_menu.forEach(item => {
        iconMenuEl.append(
            $('<li>', {data: item}).append(
                $('<a>', {href: `${item.link}`}).append(
                    $('<div>', {
                        class: 'icon', css: {
                            'background': `url(${item.icon})`,
                            'background-repeat': 'no-repeat',
                            'background-size': 'cover'
                        }
                    }),
                    $('<span>', {text: item.title})
                )
            )
        )
    })
}

export function updateViatorContent(json) {

    function noResults() {
        console.log('no results!')
        $('.content-wrapper .tagline, .content-wrapper .title').hide()

        $('.viator-items').after(`
            <div class="no-results">
                <h1>No Results Found</h1>
                <p>It seems we can't find any results based on your search.</p>
                <img src="/theme/empty-state.svg" />
                <a class="go-back-home" href="/">Go Back Home</a>
            </div>
        `)
    }

    return api.getViatorAttractions(json.id).then(attractions => {

        $('.viator-items').empty()

        // TODO: make "View More" button reveal another row each time via shuffle/isotope?
        $('.view-more').hide()

        if (!attractions || attractions.length <= 0) {
            noResults()
            return
        }

        // limit to just 24
        // TODO: make configurable?
        attractions.length = Math.min(attractions.length, 24)

        attractions.forEach(attraction => {
            $('.viator-items').append(
                $(templates.viatorAttractionItem({
                    webURL: attraction.webURL,
                    thumbnailHiResURL: attraction.thumbnailHiResURL,
                    title: attraction.title,
                    widthPct: (attraction.rating / 5) * 100,
                }))
            )
        })
    }).catch(err => {
        console.error(err)
        noResults()
    })
}

function addItem(el, item, max) {
    if (el.children().length >= max) return
    let newel = $(item)
    el.append(newel)
    return newel
}