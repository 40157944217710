import $ from 'jquery'
import jwt from 'jsonwebtoken'

let API_URL = process.env.API_URL

export function getAuthToken(id, client_key) {
    return $.ajax({
        type: "POST",
        crossDomain: true,
        url: `${API_URL}/token`,
        data: {
            id: id,
            client_key: client_key
        },
        success: function (data) {
            // data.expiresIn is seconds. Expected to be 86,400 (seconds in a day)
            if (data === undefined) {
                console.error('something went wrong')
                // if access token isn't defined....
            } else {
                console.log(`Token: ${data}`)
                var currentSeconds = new Date() / 1000
                var tokenExpiration = currentSeconds + data.expiresIn  // Save token expiration as epoch seconds.
                localStorage.setItem("authToken", data)
                localStorage.setItem("id", jwt.decode(data).property_id)
            }
        },
        error: function (data) {
            //toastr.error('The login information provided was invalid.', 'Wrangler', {timeOut: 0})
        },
        complete: function (jqXHR, status) {
            console.log(`Auth request completed with status: ${status} (${jqXHR.status})`);
        }
    })

}

// Prefilter to wrap around all calls
var prefilter = false
export function makeAjaxWrapper()
{
    if (prefilter) return
    prefilter = $.ajaxPrefilter(function (options) {

        var authToken = localStorage.getItem('authToken')

        // add authToken to all requests
        if (!options.beforeSend) {
            options.beforeSend = function (xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
            }
        }

        // parse response status code for errors/etc. (checkResponseCode is an example func)
        //var completeCallbacks = []
        //completeCallbacks.push([options.complete, checkResponseCode])
        //options.complete = completeCallbacks
    })
}

export function getConfig(propertyID) {
    if (!propertyID) propertyID = ''
    console.log(`getConfig(${propertyID})`)
    return $.ajax({
        url: `${API_URL}/config?id=${propertyID}`,
        type: "GET"
    })
}

export function getFeed(maxItems) {
    console.log(`getFeed(${maxItems})`)
    return $.ajax({
        url: `${API_URL}/cache/feed/${maxItems}`,
        type: "GET"
    })
}

export function getImage(width, height, path) {
    console.log(`getImage(${width}, ${height}, ${path})`)
    console.log(`${API_URL}/img?width=${width}&height=${height}&path=${encodeURIComponent(path)}`)
    return $.ajax({
        url: `${API_URL}/img?width=${width}&height=${height}&path=${encodeURIComponent(path)}`,
        type: "GET"
    })
}

export function getArticle(article) {
    console.log(`getArticle(${article})`)
    return $.ajax({
        url: `${API_URL}/cache/${article}`,
        type: "GET"
    })
}

export function getViatorAttractions(propertyID) {
    console.log(`getViatorAttractions(${propertyID})`)
    return $.ajax({
        url: `${API_URL}/viator/attractions/${propertyID}`,
        type: "GET"
    })
}

export function getGoldstarAttractionsByZipcode(zipcode) {
    console.log(`getGoldstarAttractionsByZipcode(${zipcode})`)
    return $.ajax({
        url: `${API_URL}/goldstar/listings/${zipcode}?limit=144`,
        type: 'GET',
    })
}

export function getGames(gamepix_id) {
    console.log(`getGames()`)
    return $.ajax({
        url: `https://games.gamepix.com/games?sid=${gamepix_id}`,
        type: 'GET'
    })
}

export function postServiceRequest(property_id, serial, guest_name, guest_room, request_item, request_quantity) {
    console.log(`postServiceRequest()`)
    return $.ajax({
        url: `${API_URL}/service_request`,
        type: 'POST',
        data: {
            property_id,
            serial,
            guest_name,
            guest_room,
            request_item,
            request_quantity
        },
        success: function (data) {
            if (data === undefined) {
                console.error('something went wrong')
            }
        },
        error: function (data) {
            console.error(`postServiceRequest error` );
        },
        complete: function (jqXHR, status) {
            console.log(`postServiceRequest completed with status: ${status} (${jqXHR.status})` );
        }
    })
}